:root{
  /*Color Variables*/
  --accent-color: #19057e;
  --primary-color: #63acff;
  --secondary-accent-color: #eef9ff;
}

body {
  font-family: "Trebuchet MS", Tahoma, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: aliceblue;
  overflow-x: hidden;
}

#intro {
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}

#about-section {
  text-align: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to top, var(--primary-color), var(--accent-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#about-section h1 {
  font-size: 50px;
}

#about-section p {
  max-width: 600px;
  text-align: left;
  margin-right: 40px;
  font-size: 20px;
}

.about-content {
  display: flex;
  justify-content: space-between;;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.about-content > div {
  flex: 1; /* Allow each section to take up equal space */
  padding: 20px; /* Optional, adjust for spacing */
}

.about-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px; /* Match slideshow size */
  margin: 0 auto; /* Center the slideshow horizontally */
  padding: 10px; /* Optional, adjust padding if necessary */
}

.about-button {
  background-image: linear-gradient(to top, var(--accent-color), var(--primary-color)); /* Gradient colors */
  -webkit-background-clip: text; /* Clip background to text */
  background-clip: text; /* For non-webkit browsers */
  background-size: 200% 100%;
  color: linear-gradient(to top, var(--accent-color), var(--primary-color));
  font-size: 20px;
  font-weight: bolder;
  padding: 10px 10px 10px;
  margin: 10px;
  transition-duration: 0.3s;
  text-align: right;
  position: relative;
  border: 5px solid transparent; /* Set border to transparent initially */
  border-image: linear-gradient(to top, var(--accent-color), var(--primary-color)); /* Gradient colors */
  border-image-slice: 1;
  padding-bottom: 5px;
  padding-top: 11px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
}

.about-button:hover {
  background-image: linear-gradient(to top, var(--primary-color), var(--accent-color)); /* Gradient colors */
  -webkit-background-clip: text; /* Clip background to text */
  background-clip: text; /* For non-webkit browsers */
  background-size: 200% 100%;
  color: linear-gradient(to top, var(--primary-color), var(--accent-color));;
  border-image: linear-gradient(to top, var(--primary-color), var(--accent-color)); /* Gradient colors */
  border-image-slice: 1;
}

.about-button svg {
  fill: url(#gradient); /* Reference the gradient defined in the SVG */
  transition: fill ease 0.3s;
}

.about-button:hover svg {
  fill: url(#hover-gradient); /* Optional hover gradient */
}

.about-button:hover svg stop:first-child {
stop-color: var(--primary-color); /* Swap the gradient colors */
}

.about-button:hover svg stop:last-child {
stop-color: var(--accent-color);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  #about-section h1 {
    font-size: 40px;
  }

  #about-section p {
    font-size: 18px;
    margin: 20px;
  }

  .about-content {
    flex-direction: column;
  }

  .about-image {
    max-width: 90%; /* Scale image width to fit container */
  }

  .about-button {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  #about-section h1 {
    font-size: 32px;
  }

  #about-section p {
    font-size: 16px;
    margin: 10px;
  }

  .about-image img {
    max-width: 90%; /* Ensure image scales properly */
  }

  .about-button {
    font-size: 16px;
  }
}

#project-section {
  text-align: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to top, var(--primary-color), var(--accent-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#project-section h1 {
  font-size: 50px;
}

.App {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  font-family: "Trebuchet MS", Tahoma, sans-serif;
}

header {
  background-color: #282c34;
  color: white;
  padding: 20px;
}

header nav ul {
  list-style: none;
  padding: 0;
}

header nav ul li {
  display: inline;
  margin: 0 15px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

main {
  padding: 0px;
}

section {
  margin: 40px 0;
}

footer {
  background-color: #ffffff;
  background: linear-gradient(to top, var(--primary-color), var(--accent-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px;
  width: 100%;
  bottom: 0;
  z-index: 2;
}
