/* Intro.css */


.intro-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full width of the parent */
  height: 100vh; /* Full height of the viewport */
  background-image: url('../assets/welcomebackground.JPG'); /* Path to the image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(8px); /* Apply blur effect */
  -webkit-filter: blur(8px); /* For Safari */
  z-index: 1; /* Behind the content */
  }
  
.overlay-content {
  position: relative;
  z-index: 2; /* Above the blurred background */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  color: white; /* Text color on top of blurred background */
  padding: 20px;
  margin: auto;
}

.intro-text{
  font-family: "Trebuchet MS", Tahoma, sans-serif;
  text-align: center;
  margin-left: 40px;
  margin-right: 20px;
}

.intro-text h1 {
  font-size: 48px; 
  max-width: 600px;
}

.intro-text p {
  font-size: 32px; 
  max-width: 600px;
}
  
.profile-image img {
  border-radius: 10%; 
  width: 60%; 
  height: 60%; 
  margin: 0;
}

.intro-button{
    color: white;
    font-size: 20px;
    font-weight: bolder;
    padding: 10px 10px 10px;
    margin: auto;
    transition-duration: 0.3s;
    text-align: center;
    border: 5px; /* Set border to transparent initially */
    border-color: white; /* Gradient colors */
    background: none;
    padding-bottom: 5px;
    padding-top: 11px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
}

.intro-button:hover {
  color: var(--primary-color);
  background: none;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .intro-text h1 {
    font-size: 36px;
  }

  .intro-text p {
    font-size: 24px;
  }

  .profile-image img {
    width: 50%;
    height: 50%;
  }

  .intro-button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .overlay-content {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .intro-text {
    margin: 10px;
  }

  .intro-text h1 {
    font-size: 28px;
  }

  .intro-text p {
    font-size: 18px;
  }

  .profile-image img {
    width: 40%;
    height: 40%;
  }

  .intro-button {
    font-size: 14px;
  }

  .intro-container {
    height: 70vh; /* Adjust background height for smaller screens */
  }
}