/* ImageSlideshow.css */

.slideshow-container {
  position: relative;
  overflow: hidden;
  margin: 0 auto; /* Centers the container horizontally */
  padding: 0;
  align-items: center;
  display: flex;
  width: 75vw;  /* 80% of the viewport width */
  height: 75vw; /* Set height proportional to width */
  max-width: 600px;
  max-height: 600px;
  justify-content: center; /* Centers content inside the container */
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.slide.active {
  opacity: 1;
}

.slideshow-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .slideshow-container {
    width: 90vw;
    height: 90vw;
    max-width: 400px;
    max-height: 400px;
  }
}
