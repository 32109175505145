/* IconDropdown.css */

.icon-button-container {
    position: fixed;
    top: 5px;
    left: 10px;
    z-index: 1000; /* Ensure it stays above other content */
  }
  
  .icon-button {
    background-image: linear-gradient(to top, var(--accent-color), var(--primary-color)); /* Gradient colors */
    -webkit-background-clip: text; /* Clip background to text */
    background-clip: text; /* For non-webkit browsers */
    background-size: 200% 100%;
    color: linear-gradient(to top, var(--accent-color), var(--primary-color));;
    font-size: 20px;
    font-weight: bolder;
    padding: 10px 10px 10px;
    margin: 10px;
    transition-duration: 0.3s;
    text-align: right;
    position: relative;
    border: 5px solid transparent; /* Set border to transparent initially */
    border-image: linear-gradient(to top, var(--accent-color), var(--primary-color)); /* Gradient colors */
    border-image-slice: 1;
    padding-bottom: 5px;
    padding-top: 11px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
  }

  .icon-button:hover {
    background-image: linear-gradient(to top, var(--primary-color), var(--accent-color)); /* Gradient colors */
    -webkit-background-clip: text; /* Clip background to text */
    background-clip: text; /* For non-webkit browsers */
    background-size: 200% 100%;
    color: linear-gradient(to top, var(--primary-color), var(--accent-color));;
    border-image: linear-gradient(to top, var(--primary-color), var(--accent-color)); /* Gradient colors */
    border-image-slice: 1;
  }

.icon-button svg {
    fill: url(#gradient); /* Reference the gradient defined in the SVG */
    transition: fill ease 0.3s;
}

.icon-button:hover svg {
    fill: url(#hover-gradient); /* Optional hover gradient */
}

.icon-button:hover svg stop:first-child {
  stop-color: var(--primary-color); /* Swap the gradient colors */
}

.icon-button:hover svg stop:last-child {
  stop-color: var(--accent-color);
}

  .dropdown-menu {
    position: absolute;
    top: 80px;
    left: 0;
    background-color: white;
    /* Initially, remove the border and shadow */
    border: none;
    box-shadow: none;
    border-radius: 5px;
    width: 150px;
     /* Transition properties */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .dropdown-menu.open {
    max-height: 500px; /* Large enough to display all menu items */
     /* Add the border and shadow when open */
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #000000;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    font-family: "Trebuchet MS", Tahoma, sans-serif;
    font-weight: 700;
  }
  
  .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:hover {
    background-image: linear-gradient(to top, var(--accent-color), var(--primary-color));
    color: white;
  }
  