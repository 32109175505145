.banner {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%; 
  background-color: rgba(255, 255, 255, 0.672);
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px 20px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.banner.scrolled {
  background-color: white;  /* Transparent when not scrolled */
}

#rightbanner {
  display: flex;
  gap: 15px; 
  margin-left: auto; 
  padding-right: 20px;
  align-items: center;
}

a {
  text-decoration: none;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}
